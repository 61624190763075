import React from "react"
import Img from "gatsby-image"
import { Visible } from "react-grid-system"

const HeroImage = ({ image }) => {
  return (
    <>
      <Visible xs sm>
        <Img fluid={image.square} />
      </Visible>
      <Visible md lg xl xxl>
        <Img fluid={image.landscape} />
      </Visible>
    </>
  )
}

export default HeroImage
