import React, { useState } from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Display } from "./typography"
import { motion, AnimatePresence } from "framer-motion"

const CardLink = ({ link }) => {
  const [hover, setHover] = useState(false)
  return (
    <StyledLink
      to={`/` + link.slug + `/`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <AnimatePresence>
        {hover && (
          <ImgWrapper
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: -5 }}
            exit={{ opacity: 0, y: 0 }}
            transition={{
              type: "spring",
              bounce: 0.5,
              duration: 1.5,
            }}
          >
            <Img fluid={link.heroImage.fluid} />
          </ImgWrapper>
        )}
      </AnimatePresence>
      <Box whileHover={{ color: "#ffffff" }}>
        <Display>{link.label}</Display>
      </Box>
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  padding-top: 70%; /* Aspect Ratio */
  position: relative; /* If you want text inside of it */
`

const Box = styled(motion.div)`
  color: ${DesignTokens.colors.primary[500]};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImgWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export default CardLink
