import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import HeroImage from "../components/heroImage"
import PageTitle from "../components/pageTitle"
import Description from "../components/description"
import Gallery from "../components/gallery"
import RelatedLink from "../components/relatedLink"
import styled from "styled-components"
import Footer from "../components/footer"
import Focus from "../components/focus"

const Page = ({ data: { page } }) => {
  // console.log(page)
  return (
    <Layout>
      <SEO title={page.title} />
      <Wrapper>
        <Header />
        <PageTitle
          label={page.label}
          title={page.title}
          subtitle={page.subtitle}
        />
        <HeroImage image={page.heroImage} />
        <Description text={page.text} />
        <Gallery gallery={page.gallery} />
        <Focus
          title={page.focusTitle}
          text={page.focusText}
          image={page.focusImage}
        />
        <RelatedLink links={page.relatedLink} />
        <Footer />
      </Wrapper>
    </Layout>
  )
}

export default Page

const Wrapper = styled.div`
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const query = graphql`
  query PageQuery($slug: String!) {
    page: datoCmsPage(slug: { eq: $slug }) {
      label
      title
      subtitle
      text
      focusTitle
      focusText
      focusImage {
        fluid(maxWidth: 700, imgixParams: { fm: "webp" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      relatedLink {
        slug
        label
        id
        heroImage {
          fluid(
            maxWidth: 768
            imgixParams: { fm: "webp", q: 75, fit: "crop", w: "700", h: "500" }
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      heroImage {
        landscape: fluid(maxWidth: 1440, imgixParams: { fm: "webp" }) {
          ...GatsbyDatoCmsFluid
        }
        square: fluid(
          maxWidth: 768
          imgixParams: { fm: "webp", q: 75, fit: "crop", w: "768", h: "768" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      gallery {
        fluid(maxWidth: 730) {
          ...GatsbyDatoCmsFluid
        }
        originalId
      }
    }
  }
`
