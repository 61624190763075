import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"
import { Caption, DisplaySmall } from "./typography"

const PageTitle = ({ label, title, subtitle }) => (
  <Wrapper>
    <Container>
      <Row>
        <Col xl={8}>
          <Content>
            <Caption>{label}</Caption>
            <div>
              <DisplaySmall as="h1">{title}</DisplaySmall>
              <DisplaySmall>{subtitle}</DisplaySmall>
            </div>
          </Content>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

const Wrapper = styled.section``

const Content = styled.section`
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default PageTitle
