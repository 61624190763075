import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-grid-system"
import CardLink from "./cardLink"

const RelatedLink = ({ links }) => (
  <Wrapper>
    <Container>
      <Row justify="center">
        <Col xl={9}>
          <Row>
            {links.map(link => (
              <Col key={link.id} xs={12} md={6}>
                <CardLink link={link} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)
const Wrapper = styled.div``

export default RelatedLink
